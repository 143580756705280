<template>
  <div class="f-col items-start items-stretch gap-3 ">
    <template v-for="{ available, shippingMethod, storeInfo } in item.shippingOptions">
      <vf-radio
        v-if="$feature.allowPickupOrder || !isPickupOrSts(shippingMethod.code)"
        :key="shippingMethod.code"
        ref="radioRef"
        v-model="selectedShippingOption"
        :aria-describedby="`shipment-radio-desc-${id}`"
        :aria-labelledby="`shipment-radio-label-${id}`"
        class="<lg:b-b <lg:b-grey-70 <lg:py-4 "
        :data-test-id="`shipment-selector-${item.id}-${shippingMethod.code}`"
        :disabled="
          loading
            || (!available && defaultStore?.sts_enabled === '0')
            || (!!storeInfo?.id && storeInfo?.storeInventory === 0 && shippingMethod.code === 'pickup')
            || (isPickupOrSts(shippingMethod.code)
              && !!storeInfo?.storeInventory
              && item.qty > storeInfo?.storeInventory
              && defaultStore?.sts_enabled !== '1')
        "
        :name="`shipmentSelector-${id}`"
        :value="shippingMethod.code"
        @update:model-value="onUpdate({ code: $event, storeId: storeInfo?.id })"
      >
        <div :id="`shipment-radio-label-${id}`" class="flex gap-1">
          {{ shippingMethod.label }}
          <span v-if="isPickupOrSts(shippingMethod.code) && shippingMethod.deliveryTime && storeInfo" class="fw-bold">
            {{ shippingMethod.deliveryTime }}
          </span>
          <vf-toggletip v-if="isPickupOrSts(shippingMethod.code)" data-test-id="pickup-tooltip" placement="top-start">
            <template #trigger>
              <span class="sr-only">{{ shippingMethod.label }}</span>
              <vf-icon name="info" size="md" />
            </template>
            {{ $t.selectStoreTooltip }}
          </vf-toggletip>
        </div>
        <div :id="`shipment-radio-desc-${id}`" class="f-col items-start gap-1">
          <template v-if="isPickupOrSts(shippingMethod.code)">
            <base-button
              class="text-left text-sm c-grey-10 underlined"
              data-test-id="pickup-location"
              @click="openModalPickupInStore"
            >
              <span
                v-if="
                  storeInfo
                    && storeInfo.name
                    && !(item.qty > storeInfo.storeInventory && defaultStore?.sts_enabled === '0')
                "
                v-html="storeInfo.name"
              />
              <template v-else>
                {{ $t.selectStore }}
              </template>
            </base-button>
            <div
              v-if="storeInfo && storeInfo.id && storeInfo.storeInventory === 0 && shippingMethod.code === 'pickup'"
              class="eyebrow <lg:hidden c-brand-3"
            >
              {{ $t.outOfStockAtThisStore }}
            </div>
          </template>
          <!-- Ship to home -->
          <p v-else class="text-sm">
            {{ $t.shipsIn }} {{ shippingMethod.deliveryTime }}
          </p>
        </div>
      </vf-radio>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { CartItem, ShippingMethodCode } from '#types/cart'
import type { LocatorSearchItem } from '#types/brandify'
import type { ShippingMethodContext } from '#types/gtm'

const props = defineProps<{
  item: CartItem
  /**
   * Disable the radios and not update the radio check state with the selected value
   */
  loading?: boolean
}>()

const emit = defineEmits<{
  /**
   * Emits when STANDARD or specific pickup store being selected
   */
  shippingOptionChange: [payload: { storeId: string }]
}>()

const { $viewport } = useNuxtApp()
const { DialogPickupInStore, DialogCartChangeShippingMethod } = useDialogsStore()

const gtmTrace = useSessionStorage('gtmTrace', {} as Record<string, ShippingMethodContext>)
const defaultStore = useLocalStorage('defaultStore', {} as LocatorSearchItem)
const id = useId()
const radioRef = ref()

const selectedShippingOption = ref<ShippingMethodCode>(
  props.item.selectedShippingOption?.shippingMethod?.code || 'standard'
)

const openModalPickupInStore = async () => {
  const confirmed = await DialogPickupInStore.open({ item: props.item })

  closeDialogs()
  if (confirmed) return
  if ($viewport.lg) selectedShippingOption.value = 'standard'
  else DialogCartChangeShippingMethod.open({ item: props.item })
}
const onUpdate = ({ code, storeId }) => {
  if (
    isPickupOrSts(code)
    && !props.item.shippingOptions.find(({ shippingMethod }) => shippingMethod.code === code)?.storeInfo
  )
    openModalPickupInStore()
  else
    emit('shippingOptionChange', { storeId })
}

watch(() => props.item.selectedShippingOption?.shippingMethod?.code, (shippingCode) => {
  selectedShippingOption.value = shippingCode || 'standard'
})

watch([selectedShippingOption, defaultStore], () => {
  gtmTrace.value[props.item.productId] = isPickupOrSts(selectedShippingOption.value)
    ? {
        shippingMethod: selectedShippingOption.value === 'sts' ? 'BOPIS_STS' : 'BOPIS_SD',
        shippingStoreId: defaultStore.value.storeid,
        shippingWindow: selectedShippingOption.value === 'sts' ? '3-5d' : '24Hrs',
      }
    : {
        shippingMethod: 'STH',
        shippingWindow: '3-5d',
      }
})
</script>
